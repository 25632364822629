import { Divider, Flex, Image, Tooltip, useDisclosure } from '@chakra-ui/react'
import { Text } from '@ubirider/pick-component-library'
import { useTranslation } from 'react-i18next'

import { TooltipModal } from '../../../../../../modals/TooltipModal/TooltipModal'
import visa from '../../../../../../assets/visa.svg'
import trash from '../../../../../../assets/trash_gray.svg'
import mastercard from '../../../../../../assets/mastercard.svg'
import amex from '../../../../../../assets/amex.svg'
import unionpay from '../../../../../../assets/unionpay.svg'
import discover from '../../../../../../assets/discover.svg'
import info from '../../../../../../assets/information_grey.svg'

export const Card = ({ card, onRemove }) => {
  const { t } = useTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const detectCardBrand = firstSixDigits => {
    switch (true) {
      case /^4/.test(firstSixDigits):
        return visa
      case /^5[1-5]/.test(firstSixDigits):
        return mastercard
      case /^(34|37)/.test(firstSixDigits):
        return amex
      case /^62/.test(firstSixDigits):
        return unionpay
      case /^6/.test(firstSixDigits):
        return discover
      default:
        return null
    }
  }

  return (
    <>
      <Flex
        bg={card.status === 'blacklisted' ? 'neutral.200' : 'neutral.25'}
        rounded='4px'
        css={'box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1)'}
        border='1px'
        borderColor={card.status === 'blacklisted' ? 'neutral.400' : 'neutral.300'}
        padding='16px'
        direction='column'
        gap='8px'
        width={{ sm: '304px', md: '304px', lg: '304px', xl: '376px' }}
        height='164px'
      >
        <Flex direction='column' gap='8px'>
          <Flex justify='space-between' align='center'>
            <Text type='textMD' weight='bold'>
              {t('home.contactless_card')}
            </Text>
            <Image src={trash} cursor='pointer' onClick={onRemove} />
          </Flex>
          <Divider border='1px solid #D0D5DD' />
        </Flex>
        <Flex direction='column' justify='space-between' gap='4px' flex='1'>
          <Flex direction='column' gap='4px'>
            <Flex justify='space-between' align='center'>
              <Text type='textMD'>{card.number.match(/.{1,4}/g).join(' ')}</Text>
              <Image w='32px' opacity={card.status === 'blacklisted' ? 0.5 : 1} src={detectCardBrand(card.number.slice(0, 6))} />
            </Flex>
            <Text type='textMD'>{`${card.expiration.substring(0, 2)}/${card.expiration.substring(2)}`}</Text>
          </Flex>
          <Flex gap='4px' align='center'>
            <Flex
              w='fit-content'
              h='24px'
              align='center'
              justify='center'
              padding='0 8px'
              border={card.status === 'blacklisted' ? '1px solid #475467' : '1px solid #D0D5DD'}
              bg={card.status === 'blacklisted' ? 'neutral.500' : 'white'}
              borderRadius='4px'
              mt='4px'
            >
              <Text type='textXS' weight='bold' css={card.status === 'blacklisted' ? { color: 'white', textTransform: 'uppercase' } : { textTransform: 'uppercase' }}>
                {card?.status === 'active' ? t('home.active') : t('home.blacklisted')}
              </Text>
            </Flex>
            {card.status === 'blacklisted' && (
              <Flex align='center' pt='4px'>
                <Tooltip
                  border='1px solid #444CE7'
                  borderRadius='4px'
                  bg='secondary.500'
                  color='secondary.25'
                  fontSize='13px'
                  fontWeight='600'
                  padding='12px 16px 12px 16px'
                  hasArrow
                  placement='right'
                  label={t('home.payment_method_invalid')}
                >
                  <Image src={info} display={{ sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }} />
                </Tooltip>
                <Flex as='button' align='center' onClick={onOpen} display={{ sm: 'flex', md: 'flex', lg: 'none', xl: 'none' }}>
                  <Image src={info} w='24px' />
                </Flex>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
      <TooltipModal onOpen={onOpen} onClose={onClose} isOpen={isOpen} text={t('home.payment_method_invalid')} />
    </>
  )
}
