import { Flex, Text } from '@chakra-ui/react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { AuthContext } from '../../../../AuthProvider'

export const TopBar = props => {
  const { t } = useTranslation()
  const Auth = useContext(AuthContext)
  const user = Auth.user

  return (
    <>
      <Flex display={{ sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' }} justifyContent='space-between'>
        <Text fontWeight='700' fontSize='30px'>
          {t('home.my_portal')}
        </Text>
          <Flex align='center'>
            <Text>
              {t('home.welcome')} {props.name ? props.name.split(' ')[0] : user?.displayName ?? user.email}
            </Text>
          </Flex>
      </Flex>
    </>
  )
}
