import { Modal, ModalOverlay, ModalContent, Flex, Image, List, Text, Box, useToast } from '@chakra-ui/react'
import { Button as UbiButton, Column } from '@ubirider/pick-component-library'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import CheckIcon from '../../assets/check_circle_green.svg'

export function TaxChangeSubmittedModal({ onOpen, onClose, isOpen }) {
  const { t } = useTranslation()

  const windowSize = useRef([window.innerWidth, window.innerHeight])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      trapFocus={false}
      closeOnOverlayClick={false}
      autoFocus={false}
      size={{ sm: 'full', md: 'full', lg: 'xl', xl: 'xl' }}
      isCentered={windowSize.current[0] >= 960}
    >
      <ModalOverlay />
      <ModalContent padding={{ sm: '16px 24px', md: '16px 24px', lg: '36px', xl: '36px' }}>
        <Flex direction='column' flex='1' gap='24px' mt={{ sm: '67px', md: '67px', lg: '0px', xl: '0px' }}>
          <Column css={{ gap: 16, justifyContent: 'center', alignItems: 'center' }}>
            <Image w='120px' src={CheckIcon} />

            <Text fontWeight='700' fontSize='24px' flex='1'>
              {t('travel_history.tax_id_change_submitted_title')}
            </Text>
          </Column>

          <Text color='neutral.600' fontWeight='400' fontSize='18px' align='center'>
            {t('travel_history.tax_id_change_submitted_description')}
          </Text>

          <UbiButton onClick={onClose} style='solid' type='success' label={t('authentication.got_it')} />
        </Flex>
      </ModalContent>
    </Modal>
  )
}
