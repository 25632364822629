import { Center, Flex, Image, List, Spinner, Text, useDisclosure } from '@chakra-ui/react'
import { Button as UbiButton } from '@ubirider/pick-component-library'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Trip } from './components/Trip'
import config from '../../../../config/configLoader'

import trainForbidden from '../../../../assets/train_forbidden.svg'
import informationIcon from '../../../../assets/information_purple.svg'
import { TaxChangeSubmittedModal } from '../../../../modals/TaxChangeSubmittedModal/TaxChangeSubmittedModal'
import { useUser } from '../../../../context/UserContext'

export const TravelHistoryTable = ({ home }) => {
  const { trips, updateTrips, loadingTrips } = useUser()

  const { t } = useTranslation()
  const navigate = useNavigate()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const userTrips = home ? trips.slice(0,5) : trips

  const onTaxIDChange = async () => {
    onClose()
    await updateTrips()
  }

  return (
    <Flex direction='column' gap='32px' flex='1'>
      <Flex
        bg={{ sm: '', md: 'neutral.100', lg: 'neutral.100', xl: 'neutral.100' }}
        padding={{ sm: '0', md: '24px', lg: '24px', xl: '24px' }}
        rounded='8px'
        gap='24px'
        direction='column'
      >
        <Flex gap='28px' direction='column' flex='1'>
          <Flex flex='1' align='center' justify='space-between'>
            <Text fontWeight='700' fontSize='24px'>
              {t('travel_history.travel_history')}
            </Text>
          </Flex>

          {loadingTrips ? (
            <Center minH='240px'>
              <Spinner thickness='6px' color='neutral.500' size='xl' />
            </Center>
          ) : userTrips != null && userTrips.length > 0 ? (
            <>
              <Text fontSize='14px' color='neutral.800'>
                {t('travel_history.travel_history_description')}
              </Text>

              <Flex direction='column' flex='1'>
                <Flex p='18.5px' gap='16px' color='white' border='1px' borderColor='secondary.200' bg='secondary.100' rounded='4px' direction='row' align='flex-start' mb='32px'>
                  <Image w='20px' src={informationIcon} />
                  <Text color='secondary.600' fontSize='14'>
                    {t('travel_history.message_trips_charged_tomorrow')}
                  </Text>
                </Flex>

                <List>
                  {userTrips.map((trip, i) => (
                    <Trip
                      key={i}
                      trip={trip}
                      onTaxIDChange={onOpen}
                    />
                  ))}
                </List>
              </Flex>
            </>
          ) : (
            <Flex
              direction='column'
              my={{ sm: '', md: '48px', lg: '48px', xl: '48px' }}
              mx={{ sm: '', md: '96px', lg: '96px', xl: '96px' }}
              justifyContent='center'
              alignItems='center'
              textAlign='center'
              gap='24px'
            >
              <Image src={trainForbidden} height='112px' />
              <Text fontSize='14px' color='neutral.800' textAlign='center'>
                {t('travel_history.travel_history_empty_state', { operatorName: config.name })}
              </Text>
              <UbiButton
                onClick={() => {
                  navigate('/faqs', { state: true })
                }}
                loading={loadingTrips}
                style='solid'
                type='primary'
                label={t('travel_history.know_more')}
              />
            </Flex>
          )}
        </Flex>

        {home && (
          <Flex
            align='center'
            justify='space-between'
            display={loadingTrips || userTrips == null || userTrips.length === 0 ? 'none' : 'flex'}
            padding={{ sm: '', md: '', lg: '0 16px', xl: '0 16px' }}
            direction={{ sm: 'column', md: 'column', lg: 'row', xl: 'row' }}
            gap={{ sm: '12px', md: '12px', lg: '48px', xl: '48px' }}
          >
            <Text alignSelf={{ sm: 'center', md: 'center', lg: '', xl: '' }} color='neutral.600' fontWeight='400' fontSize='14px'>
              {t('travel_history.displaying_latest_trips')}
            </Text>
            <Flex
              justify={{
                sm: 'center',
                md: 'center',
                lg: 'flex-start',
                xl: 'flex-start',
              }}
            >
              <UbiButton
                onClick={() => {
                  navigate('/travel-history')
                }}
                size='small'
                style='ghost'
                type='primary'
                label={t('travel_history.see_all_trips')}
              />
            </Flex>
          </Flex>
        )}
      </Flex>

      <TaxChangeSubmittedModal isOpen={isOpen} onOpen={onOpen} onClose={onTaxIDChange} />
    </Flex>
  )
}
