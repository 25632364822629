import { FacebookAuthProvider, fetchSignInMethodsForEmail, getAuth, GoogleAuthProvider, OAuthProvider, signInWithPopup } from 'firebase/auth'

import { AuthContext } from '../AuthProvider'
import { addUser } from '../api/user'

export const googleLogin = async navigate => {
  const provider = new GoogleAuthProvider()
  provider.setCustomParameters({
    prompt: 'select_account',
  })

  const auth = getAuth()
  AuthContext.user = await signInWithPopup(auth, provider)
    .then(async result => {
      const user = result.user
      //const credential = GoogleAuthProvider.credentialFromResult(result);
      //const token = credential.accessToken;
      await addUser(user.uid, user.displayName, navigator.language.substring(0, navigator.language.indexOf('-')).toUpperCase(), user.email, false, true)
      navigate('/home')
    })
    .catch(error => {
      console.log(error)
      //const email = error.customData.email;
      //const credential = GoogleAuthProvider.credentialFromError(error);
    })
}

export const facebookLogin = async navigate => {
  const provider = new FacebookAuthProvider()

  const auth = getAuth()
  AuthContext.user = await signInWithPopup(auth, provider)
    .then(async result => {
      const user = result.user
      await addUser(user.uid, user.displayName, navigator.language.substring(0, navigator.language.indexOf('-')).toUpperCase(), user.email, false, true)
      navigate('/home')
    })
    .catch(async error => {
      console.log(error)
      const credential = FacebookAuthProvider.credentialFromError(error)
      if (error.code === 'auth/account-exists-with-different-credential') {
        const methods = await fetchSignInMethodsForEmail(auth, error.customData.email)
        throw {
          provider: methods[0],
          credential: credential,
          email: error.customData.email,
        }
        //put modal call here
        //const googleProvider = new GoogleAuthProvider()
        //const appleProvider = new OAuthProvider('apple.com');
      }
    })
}

export const appleLogin = async navigate => {
  const provider = new OAuthProvider('apple.com')
  provider.setCustomParameters({
    locale: navigator.language.substring(0, navigator.language.indexOf('-')),
  })
  const auth = getAuth()
  AuthContext.user = await signInWithPopup(auth, provider)
    .then(async result => {
      //const user = result.user;
      //const credential = OAuthProvider.credentialFromResult(result);
      //const accessToken = credential.accessToken;
      //const idToken = credential.idToken;
      const user = result.user
      await addUser(user.uid, user.displayName, navigator.language.substring(0, navigator.language.indexOf('-')).toUpperCase(), user.email, false, true)
      navigate('/home')
    })
    .catch(error => {
      console.log(error)
      //const email = error.customData.email;
      //const credential = OAuthProvider.credentialFromError(error);
    })
}
