const config = {
  name: 'Fertagus',
  source: 'Fertagus',
  operator: 'fertagus',
  operatorID: 'FERTAGUS',
  agencyID: 1,
  info: {
    email: 'info@fertagus.pt',
    privacy_policy: 'https://www.fertagus.pt/pt/Privacidade',
  },
  dialogs: {
    mastercard: false,
  },
  languages: ['EN', 'ES', 'PT'],
}

export default config
