import { Modal, ModalOverlay, ModalContent, Flex, Image, List, Text } from '@chakra-ui/react'
import { Button as UbiButton } from '@ubirider/pick-component-library'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { changeInvoice } from '../../api/invoice'
import NifComponent from './components/NifComponent/NifComponent'

import infoIcon from '../../assets/information_purple.svg'
import closeButton from '../../assets/cross.svg'
import grayPlus from '../../assets/plus_gray.svg'
import arrowBackIcon from '../../assets/arrow_left.svg'
import { useUser } from '../../context/UserContext'

export function SelectBillingModal({ onOpenNewBilling, onOpen, onClose, isOpen, trip, onTaxIDChange }) {
  const { billingInfo, loadingBilling } = useUser()

  const { t } = useTranslation()

  const windowSize = useRef([window.innerWidth, window.innerHeight])

  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [checkedNif, setCheckedNif] = useState({})
  const [checkedBilling, setCheckedBilling] = useState({})

  const handleCheck = newNif => {
    setCheckedNif(newNif)
    setDisabled(false)
    billingInfo.forEach(billing => {
      if (billing.nif === newNif) setCheckedBilling(billing)
    })
  }

  const changeTaxID = async () => {
    try {
      setLoading(true)
      await changeInvoice(trip.token, trip.date, trip.invoices[0].saleID ?? trip.invoices[0].cancelSaleID, checkedBilling)
      setLoading(false)
      onClose()
      onTaxIDChange()
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      trapFocus={false}
      closeOnOverlayClick={false}
      autoFocus={false}
      size={{ sm: 'full', md: 'full', lg: 'xl', xl: 'xl' }}
      isCentered={windowSize.current[0] >= 960}
    >
      <ModalOverlay />
      <ModalContent padding={{ sm: '16px 24px', md: '16px 24px', lg: '36px', xl: '36px' }} overflow='none !important'>
        <Flex direction='column' flex='1' gap='24px' mt={{ sm: '67px', md: '67px', lg: '0px', xl: '0px' }}>
          {windowSize.current[0] < 960 && (
            <Flex alignItems='flex-start'>
              <UbiButton onClick={onClose} style='ghost' type='neutralLight' label={t('authentication.back')} leftIconProps={{ src: arrowBackIcon }} css={{ paddingLeft: '0' }} />
            </Flex>
          )}

          <Flex direction='row' justifyContent='space-between' alignItems='center'>
            <Text fontWeight='700' fontSize='24px' flex='1'>
              {t('travel_history.select_billing_information')}
            </Text>
            <Flex display={{ sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }} as='button' onClick={onClose} type='button'>
              <Image src={closeButton} />
            </Flex>
          </Flex>

          <Flex width='100%' pb='8px' p='16px' gap='16px' color='white' bg='secondary.100' rounded='4px' direction='row' align='start' border='1px solid #C7D7FE'>
            <Image w='20px' src={infoIcon} />
            <Flex align='flex-start' direction='column' gap='16px'>
              <Text color='secondary.600' fontWeight='400' fontSize='14px'>
                {t('travel_history.invoice_change_time_alert')}
              </Text>
            </Flex>
          </Flex>

          <Flex direction='column' gap='8px'>
            <List maxHeight={'300px'} overflowY='scroll' pr='12px' mr='-12px'>
              {billingInfo?.map((data, id) => (
                <NifComponent
                  key={id}
                  id={id}
                  info={data}
                  isChecked={checkedNif === data.nif}
                  onChange={() => {
                    handleCheck(checkedNif === data.nif ? null : data.nif)
                  }}
                />
              ))}
            </List>
            <UbiButton
              onClick={() => {
                onClose()
                onOpenNewBilling()
              }}
              style='ghost'
              type='primary'
              leftIconProps={{ src: grayPlus }}
              label={t('billing_information.add_new_billing_information')}
            />
          </Flex>
          <UbiButton
            onClick={changeTaxID}
            loading={loading || loadingBilling}
            disabled={disabled}
            style='solid'
            type='primary'
            label={t('travel_history.select')}
            css={{ alignSelf: 'stretch' }}
          />
        </Flex>
      </ModalContent>
    </Modal>
  )
}
