import {
  Divider,
  Flex,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useDisclosure,
} from '@chakra-ui/react'
import { Button, Tooltip } from '@ubirider/pick-component-library'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import config from '../../../../../../config/configLoader'
import { PaymentStatus } from './components/PaymentStatus'
import { UpdatePaymentBar } from './components/UpdatePaymentBar'
import { BlueSection } from './components/BlueSection'
import Invoice from './components/Invoice/Invoice'
import { VatTag } from './components/VatTag'
import { SelectBillingModal } from '../../../../../../modals/SelectBillingModal/SelectBillingModal'
import { NewBillingModal } from '../../../../../../modals/NewBillingModal/NewBillingModal'
import { useUser } from '../../../../../../context/UserContext'

import editIcon from '../../../../../../assets/edit_purple.svg'

export function Trip({ trip, onTaxIDChange }) {
  const { billingInfo } = useUser()

  const { t } = useTranslation()

  const { isOpen: isOpenSelectBilling, onOpen: onOpenSelectBilling, onClose: onCloseSelectBilling } = useDisclosure()
  const { isOpen: isOpenNewBilling, onOpen: onOpenNewBilling, onClose: onCloseNewBilling } = useDisclosure()

  const handleOpenModal = () => {
    if (billingInfo.length > 0) {
      onOpenSelectBilling()
    } else {
      onOpenNewBilling()
    }
  }

  const handleCloseModal = async () => {
    onCloseNewBilling()
    onOpenSelectBilling()
  }

  return (
    <Accordion allowToggle>
      <AccordionItem marginBlock='8px' rounded='4px'>
        {({ isExpanded }) => (
          <Flex
            bg={isExpanded ? 'secondary.25' : 'neutral.25'}
            rounded='4px'
            border='1px'
            borderColor={isExpanded ? 'secondary.500' : 'neutral.300'}
            _hover={{
              border: '1px',
              borderColor: 'secondary.500',
            }}
            direction='column'
            css={'box-shadow: 0 0 20px rgba(0, 0, 0, 0.1)'}
          >
            <AccordionButton
              paddingBlock='16px'
              _hover={{}}
              _expanded={{
                paddingBottom: '0px',
              }}
            >
              <Flex display={{ sm: 'flex', md: 'none', lg: 'none', xl: 'none' }}>
                <PaymentStatus status={trip.status} isExpanded={isExpanded} />
              </Flex>
              <Flex flex='1' justifyContent='space-between' alignItems='center'>
                <Text color='neutral.800' fontSize='13px'>
                  {DateTime.fromMillis(trip.date, {
                    zone: 'utc',
                  })
                    .setZone('Europe/Lisbon')
                    .toFormat('dd/MM/yyyy')}
                </Text>
                <Text display={isExpanded ? { sm: 'none', md: 'none', lg: 'flex', xl: 'flex' } : 'flex'} color='neutral.800' fontSize='13px'>
                  {DateTime.fromMillis(trip.date, {
                    zone: 'utc',
                  })
                    .setZone('Europe/Lisbon')
                    .toFormat('HH:mm')}
                </Text>
                <Text display={{ sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' }} color='neutral.800' fontSize='13px'>
                  {trip.cardPAN.match(/.{1,4}/g).join(' ')}
                </Text>

                <VatTag vatNumber={trip.invoices[0]?.vatNumber} billingProfiles={billingInfo} />

                {config.operator === 'fertagus' && (
                  <Text color='neutral.800' fontSize='13px' fontWeight='700'>
                    {(Math.round(trip.amount * 100) / 100).toFixed(2)}€
                  </Text>
                )}
                {config.operator === 'mts' && (
                  <Text color='neutral.800' fontSize='13px' fontWeight='700'>
                    {(Math.round(trip.amount * trip.qty * 100) / 100).toFixed(2)}€
                  </Text>
                )}
                <Flex display={{ sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' }} align='center' gap='13px'>
                  <PaymentStatus status={trip.status} />
                  <AccordionIcon />
                </Flex>
                <Flex display={{ sm: 'flex', md: 'none', lg: 'none', xl: 'none' }} align='center' gap='13px'>
                  <AccordionIcon />
                </Flex>
              </Flex>
            </AccordionButton>
            <AccordionPanel>
              <Divider color='neutral.300' />
              <Flex pt='8px'>

                <Flex direction='column' flex='1'>
                  {config.operator === 'fertagus' && (
                    <Flex marginInline='8px' direction='column' flex='1' display={{ sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' }}>
                      <UpdatePaymentBar status={trip.status} amount={trip.amount} />
                      <Text fontSize='14px' fontWeight='700'>
                        {t('travel_history.selected_trip')}
                      </Text>

                      <Flex justifyContent='space-between' gap='8px'>
                        <Flex gap='8px'>
                          <Text fontSize='13px'>{t('travel_history.origin_destination')}</Text>
                          <Text fontSize='13px' fontWeight='600'>
                            {trip.plannedOrigin} - {trip.plannedDestination}
                          </Text>
                        </Flex>
                        <Flex gap='8px'>
                          <Text fontSize='13px'>{t('travel_history.discount')}</Text>
                          <Text fontSize='13px' fontWeight='600'>
                            {(Math.round(trip.discount * 100) / 100).toFixed(2)}€
                          </Text>
                        </Flex>
                        <Flex gap='8px'>
                          <Text fontSize='13px'>{t('travel_history.total')}</Text>
                          <Text fontSize='13px' fontWeight='600'>
                            {isNaN(trip.plannedAmount) ? '- ' : (Math.round(trip.plannedAmount * 100) / 100).toFixed(2)}€
                          </Text>
                        </Flex>
                      </Flex>
                    </Flex>
                  )}

                  {config.operator === 'mts' && (
                    <Flex marginInline='8px' direction='column' flex='1' display={{ sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' }}>
                      <UpdatePaymentBar status={trip.status} amount={trip.amount} />
                      <Text fontSize='14px' fontWeight='700'>
                        {t('travel_history.trip')}
                      </Text>

                      <Flex justifyContent='space-between' gap='8px'>
                        <Flex gap='8px'>
                          <Text fontSize='13px'>{t('travel_history.fare')}</Text>
                          <Text fontSize='13px' fontWeight='600'>
                            {trip.fare ?? '-'}
                          </Text>
                        </Flex>
                        <Flex gap='8px'>
                          <Text fontSize='13px'>{t('travel_history.discount')}</Text>
                          <Text fontSize='13px' fontWeight='600'>
                            {(Math.round(trip.discount * 100) / 100).toFixed(2)}€
                          </Text>
                        </Flex>
                        <Flex gap='8px'>
                          <Text fontSize='13px'>{t('travel_history.final_price')}</Text>
                          <Text fontSize='13px' fontWeight='600'>
                            {isNaN(trip.amount) ? '- ' : (Math.round(trip.amount * 100) / 100).toFixed(2)}€
                          </Text>
                        </Flex>
                        <Flex gap='8px'>
                          <Text fontSize='13px'>{t('travel_history.qty_quantity')}</Text>
                          <Text fontSize='13px' fontWeight='600'>
                            {trip.qty}
                          </Text>
                        </Flex>
                        <Flex gap='8px'>
                          <Text fontSize='13px'>{t('travel_history.total')}</Text>
                          <Text fontSize='13px' fontWeight='600'>
                            {isNaN(trip.amount) ? '- ' : (Math.round(trip.amount * trip.qty * 100) / 100).toFixed(2)}€
                          </Text>
                        </Flex>
                      </Flex>
                    </Flex>
                  )}

                  <Flex gap='8px' padding='0 8px' direction='column' display={{ sm: 'flex', md: 'none', lg: 'none', xl: 'none' }}>
                    <UpdatePaymentBar status={trip.status} amount={trip.amount} />
                    <Flex gap='4px' direction='column'>
                      <Text fontSize='14px' fontWeight='700'>
                        {t('travel_history.date')}
                      </Text>
                      <Flex gap='24px'>
                        <Text color='neutral.800' fontSize='13px'>
                          {DateTime.fromMillis(trip.date, {
                            zone: 'utc',
                          })
                            .setZone('Europe/Lisbon')
                            .toFormat('dd/MM/yyyy')}
                        </Text>
                        <Text color='neutral.800' fontSize='13px'>
                          {DateTime.fromMillis(trip.date, {
                            zone: 'utc',
                          })
                            .setZone('Europe/Lisbon')
                            .toFormat('HH:mm')}
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex gap='4px' direction='column'>
                      <Text fontSize='14px' fontWeight='700'>
                        {t('common.card_number')}
                      </Text>
                      <Text fontSize='13px'>{trip.cardPAN.match(/.{1,4}/g).join(' ')}</Text>
                    </Flex>
                  </Flex>

                  {config.operator === 'fertagus' && <BlueSection origin={trip.origin} destination={trip.destination} amount={trip.amount} />}

                  {config.operator === 'fertagus' && (
                    <Flex justifyContent='space-between' gap='8px' padding='8px' direction='column' display={{ sm: 'flex', md: 'none', lg: 'none', xl: 'none' }}>
                      <Text fontSize='14px' fontWeight='700'>
                        {t('travel_history.selected_trip')}
                      </Text>
                      <Flex gap='4px' direction='column'>
                        <Text fontSize='13px'>{t('travel_history.origin_destination')}</Text>
                        <Text fontSize='13px' fontWeight='600'>
                          {trip.plannedOrigin} - {trip.plannedDestination}
                        </Text>
                      </Flex>
                      <Flex gap='4px' direction='column'>
                        <Text fontSize='13px'>{t('travel_history.discount')}</Text>
                        <Text fontSize='13px' fontWeight='600'>
                          {(Math.round(trip.discount * 100) / 100).toFixed(2)}€
                        </Text>
                      </Flex>
                      <Flex gap='4px' direction='column'>
                        <Text fontSize='13px'>{t('travel_history.total')}</Text>
                        <Text fontSize='13px' fontWeight='600'>
                          {isNaN(trip.plannedAmount) ? '- ' : (Math.round(trip.plannedAmount * 100) / 100).toFixed(2)}€
                        </Text>
                      </Flex>
                    </Flex>
                  )}

                  {config.operator === 'mts' && (
                    <Flex justifyContent='space-between' gap='8px' padding='8px' direction='column' display={{ sm: 'flex', md: 'none', lg: 'none', xl: 'none' }}>
                      <Text fontSize='14px' fontWeight='700'>
                        {t('travel_history.trip')}
                      </Text>
                      <Flex gap='4px' direction='column'>
                        <Text fontSize='13px'>{t('travel_history.fare')}</Text>
                        <Text fontSize='13px' fontWeight='600'>
                          {trip.fare ?? '-'}
                        </Text>
                      </Flex>
                      <Flex gap='4px' direction='column'>
                        <Text fontSize='13px'>{t('travel_history.discount')}</Text>
                        <Text fontSize='13px' fontWeight='600'>
                          {(Math.round(trip.discount * 100) / 100).toFixed(2)}€
                        </Text>
                      </Flex>
                      <Flex gap='4px' direction='column'>
                        <Text fontSize='13px'>{t('travel_history.final_price')}</Text>
                        <Text fontSize='13px' fontWeight='600'>
                          {isNaN(trip.amount) ? '- ' : (Math.round(trip.amount * 100) / 100).toFixed(2)}€
                        </Text>
                      </Flex>
                      <Flex gap='4px' direction='column'>
                        <Text fontSize='13px'>{t('travel_history.qty_quantity')}</Text>
                        <Text fontSize='13px' fontWeight='600'>
                          {trip.qty}
                        </Text>
                      </Flex>
                      <Flex gap='4px' direction='column'>
                        <Text fontSize='13px'>{t('travel_history.total')}</Text>
                        <Text fontSize='13px' fontWeight='600'>
                          {isNaN(trip.amount) ? '- ' : (Math.round(trip.amount * 100) / 100).toFixed(2)}€
                        </Text>
                      </Flex>
                    </Flex>
                  )}

                  <Flex gap='4px' margin='8px' align='center'>
                    <Text fontSize='13px' fontWeight='600'>
                      {t('common.tax_id')}
                    </Text>
                    <Text fontSize='13px' fontWeight='400'>
                      {trip?.invoices[0]?.vatNumber && trip?.invoices[0]?.vatNumber !== '-'
                        ? trip.invoices[0].vatNumber.replace(/(\d{3})(\d{3})(\d{3})/, '$1 $2 $3')
                        : '999 999 999'}
                    </Text>
                    {trip?.processingInvoiceChange !== undefined ?
                      (<Tooltip label={t('travel_history.tax_id_change_submitted_tooltip')} css={{ width: 240 }}>
                        <Button
                          disabled={true}
                          size='small'
                          style='ghost'
                          type='primary'
                          leftIconProps={{ src: editIcon }}
                          css={{ padding: 8 }}
                        />
                      </Tooltip>)
                      :
                      (<Button
                        onClick={handleOpenModal}
                        size='small'
                        style='ghost'
                        type='primary'
                        leftIconProps={{ src: editIcon }}
                        css={{ padding: 8 }}
                      />)
                    }
                  </Flex>

                  <Flex padding='8px 0 8px 8px' direction='column' gap='8px'>
                    <Flex alignItems='flex-start' gap='8px'>
                      <Text fontSize='13px' fontWeight='600'>
                        {t('travel_history.documents')}
                      </Text>
                    </Flex>
                    <Divider border='1px solid #D0D5DD' />
                    <Flex
                      alignItems='flex-start'
                      display={{
                        sm: 'none',
                        md: 'flex',
                        lg: 'flex',
                        xl: 'flex',
                      }}
                      flex='1'
                    >
                      <Flex flex='2'>
                        <Text fontSize='13px' color='#1D2939'>
                          {t('travel_history.date')}
                        </Text>
                      </Flex>
                      <Flex flex='3'>
                        <Text fontSize='13px' color='#1D2939'>
                          {t('travel_history.invoice')}
                        </Text>
                      </Flex>
                      <Flex flex='2'>
                        <Text fontSize='13px' color='#1D2939'>
                          {t('travel_history.status')}
                        </Text>
                      </Flex>
                      <Flex flex='3'>
                        <Text fontSize='13px' color='#1D2939'>
                          {t('travel_history.credit_note')}
                        </Text>
                      </Flex>
                    </Flex>
                    {trip.invoices.map((invoice, i) => (
                      <Invoice key={i} invoice={invoice} />
                    ))}
                  </Flex>
                </Flex>
              </Flex>
            </AccordionPanel>
          </Flex>
        )}
      </AccordionItem>

      <SelectBillingModal onOpen={onOpenSelectBilling} onClose={onCloseSelectBilling} isOpen={isOpenSelectBilling} onTaxIDChange={onTaxIDChange} onOpenNewBilling={onOpenNewBilling} trip={trip} />
      <NewBillingModal onOpen={onOpenNewBilling} onClose={handleCloseModal} isOpen={isOpenNewBilling} blueInfo />
    </Accordion>
  )
}
