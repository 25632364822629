import { Box, Flex, Image, Text, useToast } from '@chakra-ui/react'
import { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { getCards, updateSettledCards } from '../../api/cards'
import { AuthContext } from '../../AuthProvider'
import checkWhiteIcon from '../../assets/check_circle_white.svg'
import closeWhiteIcon from '../../assets/cross_white_24.svg'

import { PaymentDueBar } from './components/PaymentDueBar'
import { CardsMenu } from './components/CardsMenu'
import { TravelHistoryTable } from '../TravelHistory/components/TravelHistoryTable'
import { useUser } from '../../context/UserContext'

function Home() {
  const { trips, cards, updateData, loading } = useUser()

  const { t } = useTranslation()
  const navigate = useNavigate()
  const Auth = useContext(AuthContext)
  const toast = useToast()
  const toastIdRef = useRef()

  function close() {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current)
    }
  }

  const [cardAdded, setAdded] = useState(false)
  const [reloadData, triggerReloadData] = useState(true)

  useEffect(() => {
    const handleAsyncLogic = async () => {
      const settlePayment = async () => {
        const savedData = localStorage.getItem('SettledCards')
        try {
          const res = await updateSettledCards(JSON.parse(savedData))
          if (res.message === 'success') {
            localStorage.removeItem('SettledCards')
            toastIdRef.current = toast({
              position: 'top',
              duration: 5000,
              isClosable: true,
              render: () => (
                <Box alignItems='center' color='white' bg='#01AE70' border='1px solid #067F5B'
                     boxShadow='box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.05)' borderRadius='4px'>
                  <Flex justifyContent='space-between' gap='16px' padding='16px'>
                    <Image src={checkWhiteIcon} />
                    <Text fontSize='14px' fontWeight='600'>
                      {savedData.length > 1 ? t('travel_history.payment_settled_cards') : t('travel_history.payment_settled_card')}
                    </Text>
                    <Image cursor='pointer' onClick={close} src={closeWhiteIcon} />
                  </Flex>
                </Box>
              ),
            })
            navigate('/home')
          } else {
            // Show error toast
            toastIdRef.current = toast({
              position: 'top',
              duration: 5000,
              isClosable: true,
              render: () => (
                <Box alignItems='center' color='white' bg='#E8554F' border='1px solid #D23636'
                     boxShadow='box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.05)' borderRadius='4px'>
                  <Flex justifyContent='space-between' gap='16px' padding='16px'>
                    <Image src={checkWhiteIcon} />
                    <Text fontSize='14px' fontWeight='600'>
                      {t('travel_history.settle_request_not_registered_title')}
                    </Text>
                    <Image cursor='pointer' onClick={close} src={closeWhiteIcon} />
                  </Flex>
                </Box>
              ),
            })
            navigate('/home')
          }
        } catch (error) {
          console.error(error)
          // Handle error case with a toast
          toastIdRef.current = toast({
            position: 'top',
            duration: 5000,
            isClosable: true,
            render: () => (
              <Box alignItems='center' color='white' bg='#E8554F' border='1px solid #D23636'
                   boxShadow='box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.05)' borderRadius='4px'>
                <Flex justifyContent='space-between' gap='16px' padding='16px'>
                  <Image src={checkWhiteIcon} />
                  <Text fontSize='14px' fontWeight='600'>
                    {t('travel_history.settle_request_not_registered_title')}
                  </Text>
                  <Image cursor='pointer' onClick={close} src={closeWhiteIcon} />
                </Flex>
              </Box>
            ),
          })
          navigate('/home')
        }
      }

      const fetchData = async () => {
        await updateData()
        setAdded(false)
      }

      if (Auth.user) {
        if (window.location.pathname === '/home/success') {
          await settlePayment()
          await fetchData()
        } else if (window.location.pathname === '/home/fail') {
          navigate('/home')
          await fetchData()
          toastIdRef.current = toast({
            position: 'top',
            duration: 5000,
            isClosable: true,
            render: () => (
              <Box alignItems='center' color='white' bg='#E8554F' border='1px solid #D23636'
                   boxShadow='box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.05)' borderRadius='4px'>
                <Flex justifyContent='space-between' gap='16px' padding='16px'>
                  <Image src={checkWhiteIcon} />
                  <Text fontSize='14px' fontWeight='600'>
                    {t('travel_history.settle_request_not_registered_title')}
                  </Text>
                  <Image cursor='pointer' onClick={close} src={closeWhiteIcon} />
                </Flex>
              </Box>
            ),
          })
        } else if (reloadData) {
          await fetchData()
          triggerReloadData(false)
        }
      }
    }

    handleAsyncLogic()
  }, [Auth.user, cardAdded, reloadData])

  return (
    <Flex direction='column' gap='20px' flex='1'>
      <PaymentDueBar trips={trips} />
      <CardsMenu added={setAdded} cards={cards} loading={loading} />

      {!loading && cards.length > 0 && <TravelHistoryTable home />}
    </Flex>
  )
}

export default Home
