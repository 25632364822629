import { createContext, useState, useEffect, useContext } from 'react'
import { getCards } from '../api/cards'
import { getTrips, getUserData } from '../api/user'
import { getBillingInfo } from '../api/billing'
import { AuthContext } from '../AuthProvider'

const UserContext = createContext()

export const UserProvider = ({ children }) => {
  const user = useContext(AuthContext).user

  const [billingInfo, setBillingInfo] = useState([])
  const [cards, setCards] = useState([])
  const [trips, setTrips] = useState([])
  const [userData, setUserData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [loadingTrips, setLoadingTrips] = useState(false)
  const [loadingBilling, setLoadingBilling] = useState(false)
  const [loadingCards, setLoadingCards] = useState(false)
  const [loadingUser, setLoadingUser] = useState(false)

  useEffect(() => {
    const fetchInitialData = async () => {
      setLoading(true)
      setLoadingTrips(true)
      setLoadingBilling(true)
      setLoadingCards(true)
      setLoadingUser(true)

      try {
        const tripsResponse = await getTrips()
        const billingInfoResponse = await getBillingInfo()
        const cardsResponse = await getCards()
        const userDataResponse = await getUserData()

        setTrips(tripsResponse)
        setBillingInfo(billingInfoResponse)
        setCards(cardsResponse)
        setUserData(userDataResponse)
      } catch (error) {
        console.error('Failed to fetch initial data:', error)
      } finally {
        setLoading(false)
        setLoadingTrips(false)
        setLoadingBilling(false)
        setLoadingCards(false)
        setLoadingUser(false)
      }
    }

    if (user) fetchInitialData()
  }, [user])


  const updateData = async () => {
    setLoading(true)
    setLoadingTrips(true)
    setLoadingBilling(true)
    setLoadingCards(true)
    setLoadingUser(true)

    try {
      const tripsResponse = await getTrips()
      const billingInfoResponse = await getBillingInfo()
      const cardsResponse = await getCards()
      const userDataResponse = await getUserData()

      setTrips(tripsResponse)
      setBillingInfo(billingInfoResponse)
      setCards(cardsResponse)
      setUserData(userDataResponse)
    } catch (error) {
      console.error('Failed to update data:', error)
    } finally {
      setLoading(false)
      setLoadingTrips(false)
      setLoadingBilling(false)
      setLoadingCards(false)
      setLoadingUser(false)
    }
  }

  const updateTrips = async () => {
    setLoadingTrips(true)

    try {
      const tripsResponse = await getTrips()
      setTrips(tripsResponse)
    } catch (error) {
      console.error('Failed to update trips:', error)
    } finally {
      setLoadingTrips(false)
    }
  }

  const updateBillingInfo = async () => {
    setLoadingBilling(true)

    try {
      const billingInfoResponse = await getBillingInfo()
      setBillingInfo(billingInfoResponse)
    } catch (error) {
      console.error('Failed to update billing:', error)
    } finally {
      setLoadingBilling(false)
    }
  }

  const updateCards = async () => {
    setLoadingCards(true)

    try {
      const cardsResponse = await getCards()
      setCards(cardsResponse)
    } catch (error) {
      console.error('Failed to update cards:', error)
    } finally {
      setLoadingCards(false)
    }
  }

  const updateUserData = async () => {
    setLoadingUser(true)

    try {
      const userDataResponse = await getUserData()
      setUserData(userDataResponse)
    } catch (error) {
      console.error('Failed to update user:', error)
    } finally {
      setLoadingUser(false)
    }
  }

  return (
    <UserContext.Provider
      value={{
        trips,
        billingInfo,
        cards,
        userData,
        loading,
        loadingTrips,
        loadingBilling,
        loadingCards,
        loadingUser,
        updateData,
        updateTrips,
        updateBillingInfo,
        updateCards,
        updateUserData,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const useUser = () => useContext(UserContext)