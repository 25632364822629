import { Flex } from '@chakra-ui/react'
import { TravelHistoryTable } from './components/TravelHistoryTable'
import { PaymentDueBar } from '../Home/components/PaymentDueBar'
import { useUser } from '../../context/UserContext'

function TravelHistory() {
  const { trips } = useUser()

  return (
    <Flex direction='column' gap='20px' flex='1'>
      <PaymentDueBar trips={trips} />
      <TravelHistoryTable />
    </Flex>
  )
}

export default TravelHistory
