import firebase from '../Firebase'

const { REACT_APP_MY_ENV, REACT_APP_CONFIG } = process.env

export const getToken = async () => {
  return new Promise((resolve, reject) => {
    firebase?.currentUser
      ?.getIdToken(true)
      .then(idToken => {
        resolve(idToken)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const getBFFUrl = () => {
  if (REACT_APP_CONFIG === 'local') {
    switch (REACT_APP_MY_ENV) {
      case 'userportal-dev':
        return 'http://localhost:3000/dev'
      case 'userportal-tests':
        return 'http://localhost:3000/tests'
      case 'userportal':
        return 'http://localhost:3000/prod'
      default:
        return ''
    }
  }

  return `https://${REACT_APP_MY_ENV}.api.ubirider.com`
}
