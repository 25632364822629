import axios from 'axios'
import config from '../config/configLoader'
import { getBFFUrl, getToken } from './requests'

export const getBillingInfo = async () => {
  const token = await getToken()

  const res = await axios.get(`${getBFFUrl()}/billingInfo`, {
    params: {
      operator: config.operatorID,
    },
    headers: {
      'x-access-token': token,
      operator: config.source,
    },
  })

  return res.data.data.billingInfo
}

export const setBillingInfo = async billingInfo => {
  const token = await getToken()
  const res = await axios.post(
    `${getBFFUrl()}/billingInfo`,
    {
      profileName: billingInfo.profileName,
      name: billingInfo.name,
      nif: billingInfo.nif,
      address: billingInfo.address,
      postalCode: billingInfo.postalCode,
      city: billingInfo.city,
      country: billingInfo.country,
      operator: config.operatorID,
    },
    {
      headers: {
        'x-access-token': token,
        operator: config.source,
      },
    }
  )

  return res.data.data.billingInfo
}

export const deleteBillingInfo = async id => {
  const token = await getToken()
  const res = await axios.delete(`${getBFFUrl()}/billingInfo?id=${id}`, {
    params: {
      operator: config.operatorID,
    },
    headers: {
      'x-access-token': token,
      operator: config.source,
    },
  })

  return res.data.data.billingInfo
}

export const setDefaultBillingInfo = async id => {
  const token = await getToken()
  return await axios.put(
    `${getBFFUrl()}/billingInfo?id=${id}&default=true`,
    {
      operator: config.operatorID,
    },
    {
      headers: {
        'x-access-token': token,
        operator: config.source,
      },
    }
  )
}

export const editBillingInfo = async (billingInfo, id) => {
  const token = await getToken()
  const res = await axios.put(
    `${getBFFUrl()}/billingInfo?id=${id}`,
    {
      profileName: billingInfo.profileName,
      address: billingInfo.address,
      city: billingInfo.city,
      name: billingInfo.name,
      nif: billingInfo.nif,
      postalCode: billingInfo.postalCode,
      country: billingInfo.country,
      operator: config.operatorID,
    },
    {
      headers: {
        'x-access-token': token,
        operator: config.source,
      },
    }
  )

  return res.data.data.billingInfo
}
