import axios from 'axios'
import config from '../config/configLoader'
import { getBFFUrl, getToken } from './requests'

export const addUser = async (uid, name, lang, email, terms, verify) => {
  const token = await getToken()
  const res = await axios.post(
    `${getBFFUrl()}/user`,
    {
      uid: uid,
      name: name,
      email: email,
      lang: lang,
      terms: terms,
      verify: verify,
      operator: config.operatorID,
    },
    {
      headers: {
        'x-access-token': token,
        operator: config.source,
      },
    }
  )

  return res.data
}

export const getUserData = async () => {
  const token = await getToken()

  const res = await axios.get(`${getBFFUrl()}/user`, {
    params: {
      operator: config.operatorID,
    },
    headers: {
      'x-access-token': token,
      operator: config.source,
    },
  })

  return res.data.data
}

export const getTrips = async () => {
  const token = await getToken()

  const res = await axios.get(`${getBFFUrl()}/trips`, {
    params: {
      operator: config.operatorID,
    },
    headers: {
      'x-access-token': token,
      operator: config.source,
    },
  })

  console.log(res)
  return res.data.data
}

export const updateTermsConditions = async () => {
  const token = await getToken()

  const res = await axios.get(`${getBFFUrl()}/terms`, {
    params: {
      operator: config.operatorID,
    },
    headers: {
      'x-access-token': token,
      operator: config.source,
    },
  })

  return res.data.data
}

export const updateName = async name => {
  const token = await getToken()

  const res = await axios.get(`${getBFFUrl()}/name?name=${name}`, {
    params: {
      operator: config.operatorID,
    },
    headers: {
      'x-access-token': token,
      operator: config.source,
    },
  })

  return res.data.data
}

export const updateEmail = async email => {
  const token = await getToken()

  const res = await axios.get(`${getBFFUrl()}/email?email=${email}`, {
    params: {
      operator: config.operatorID,
    },
    headers: {
      'x-access-token': token,
      operator: config.source,
    },
  })

  return res.data.data
}

export const updateLanguage = async lang => {
  const token = await getToken()

  const res = await axios.get(`${getBFFUrl()}/lang?lang=${lang}`, {
    params: {
      operator: config.operatorID,
    },
    headers: {
      'x-access-token': token,
      operator: config.source,
    },
  })

  return res.data.data
}
