import { Flex, Image, Link, Modal, ModalContent, ModalOverlay, Text } from '@chakra-ui/react'
import { Button as UbiButton } from '@ubirider/pick-component-library'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link as ReactLink } from 'react-router-dom'

import shield from '../../assets/shield.svg'
import closeButton from '../../assets/cross.svg'
import { handleFinaroPayment } from '../../api/payment'

export const RedirectModal = props => {
  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)

  async function handleRedirect() {
    setLoading(true)
    try {
      localStorage.setItem('SettledCards', JSON.stringify(props.trips))
      const res = await handleFinaroPayment(props.amount * 100)
      await window.location.replace(res.data)
      setLoading(false)
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      size={{ sm: 'full', md: 'full', lg: '', xl: '' }}
      trapFocus={false}
      closeOnOverlayClick={false}
      autoFocus={false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        borderRadius='8px'
        padding={{
          sm: '16px 24px',
          md: '16px 24px',
          lg: '48px 56px',
          xl: '48px 56px',
        }}
        maxW={{ lg: 600 }}
      >
        <Flex justify='center' direction='column' mt={{ sm: '67px', md: '67px', lg: '0px', xl: '0px' }}>
          <Flex direction='row' justifyContent='flex-end' alignItems='center'>
            <Flex as='button' onClick={props.onClose}>
              <Image src={closeButton} />
            </Flex>
          </Flex>
          <Flex direction='column' gap='32px' align='center'>
            <Image src={shield} />
            <Text fontWeight='700' fontSize={{ sm: '20px', md: '20px', lg: '24px', xl: '24px' }} color='neutral.800' align='center'>
              {t('travel_history.secure_connection_title')}
            </Text>
            <Text fontWeight='600' fontSize={{ sm: '14px', md: '14px', lg: '20px', xl: '20px' }} color='neutral.600' align='center'>
              {t('travel_history.secure_connection_description')}
            </Text>

            <Flex gap='16px' display={{ sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }}>
              <UbiButton onClick={props.onClose} style='outline' type='neutralDark' label={t('authentication.back')} css={{ width: '152px' }} />
              <UbiButton onClick={handleRedirect} loading={loading} style='solid' type='primary' label={t('home.continue')} css={{ width: '152px' }} />
            </Flex>

            <Flex gap='16px' alignSelf='stretch' direction='column' display={{ sm: 'flex', md: 'flex', lg: 'none', xl: 'none' }}>
              <UbiButton onClick={handleRedirect} loading={loading} style='solid' type='primary' label={t('home.continue')} css={{ width: '100%' }} />
              <UbiButton onClick={props.onClose} style='outline' type='neutralDark' label={t('authentication.back')} css={{ width: '100%' }} />
            </Flex>

            <Text fontSize='14px' color='#475467' textAlign='center'>
              <Trans i18nKey='travel_history.payment_partner_redirect_disclaimer'>
                To learn more about how we use your data consult our FAQS and the privacy policies of our partner:{' '}
                <Link sx={{ textDecoration: 'underline', fontWeight: '600' }} as={ReactLink} to='https://finaro.com/privacy/' isExternal>
                  Finaro
                </Link>
                .
              </Trans>
            </Text>
          </Flex>
        </Flex>
      </ModalContent>
    </Modal>
  )
}
