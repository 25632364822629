import { Image, Flex, Text, Divider } from '@chakra-ui/react'
import { Button as UbiButton } from '@ubirider/pick-component-library'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'

import downloadIcon from '../../../../../../../../assets/download.svg'

export default function Invoice({ invoice, onClick }) {
  const { t } = useTranslation()

  const inputDate = invoice?.date
    ? DateTime.fromMillis(invoice?.date, {
      zone: 'utc',
    })
      .setZone('Europe/Lisbon')
      .toFormat('dd/MM/yyyy HH:mm')
    : '—'

  return (
    <>
      <Flex flex='1' display={{ sm: 'none', md: 'flex' }}>
        <Flex flex='2' align='center'>
          <Text fontSize='13px' fontWeight='600'>
            {inputDate}
          </Text>
        </Flex>
        <Flex flex='3' align='center'>
          {invoice.invoiceURL ? (
            <Flex w='100%' align='center' gap='16px'>
              <a href={invoice.invoiceURL} target='_blank' rel='noreferrer'>
                <UbiButton onClick={onClick} disabled={!invoice.invoiceURL} size='small' style='typo' type='neutralDark' leftIconProps={{ src: downloadIcon, css: { width: 16 }  }} />
              </a>
              <Text fontSize='13px' fontWeight='600'>
                {invoice.invoiceName}
              </Text>
            </Flex>
          ) : (
            <Text fontSize='13px' fontWeight='600'>
              —
            </Text>
          )}
        </Flex>
        <Flex flex='2' align='center'>
          {invoice.invoiceURL ? (
            <Text fontSize='13px' fontWeight='600' textTransform='uppercase' color={invoice.status === 'issued' ? '#6172F3' : invoice.status === 'cancelled' ? '#98A2B3' : '#C6901E'}>
              {t(`travel_history.${invoice.status}`)}
            </Text>
          ) : (
            <Text fontSize='13px' fontWeight='600'>
              —
            </Text>
          )}
        </Flex>
        <Flex flex='3' align='center'>
          {invoice.creditNoteURL ? (
            <Flex w='100%' align='center' gap='16px'>
              <a href={invoice.creditNoteURL} target='_blank' rel='noreferrer'>
                <UbiButton onClick={onClick} size='small' style='typo' type='neutralDark' leftIconProps={{ src: downloadIcon, css: { width: 16 } }} />
              </a>
              <Text fontSize='13px' fontWeight='600'>
                {invoice.creditNoteName}
              </Text>
            </Flex>
          ) : (
            <Text fontSize='13px' fontWeight='600'>
              —
            </Text>
          )}
        </Flex>
      </Flex>
      <Flex gap='8px' direction='column' display={{ sm: 'flex', md: 'none' }}>
        <Flex gap='4px'>
          <Text fontSize='13px' fontWeight='400' w='50%'>
            {t('travel_history.date')}
          </Text>
          <Text fontSize='13px' fontWeight='600'>
            {inputDate}
          </Text>
        </Flex>
        <Flex gap='4px'>
          <Text fontSize='13px' fontWeight='400' w='50%'>
            {t('travel_history.invoice')}
          </Text>
          {invoice.invoiceURL ? (
            <Flex w='100%' justify='space-between' align='center' mr='16px'>
              <Text fontSize='13px' fontWeight='600'>
                {invoice.invoiceName}
              </Text>
              <a href={invoice.invoiceURL} target='_blank' rel='noreferrer' download>
                <Image src={downloadIcon} />
              </a>
            </Flex>
          ) : (
            <Text fontSize='13px' fontWeight='600'>
              —
            </Text>
          )}
        </Flex>
        <Flex gap='4px'>
          <Text fontSize='13px' fontWeight='400' w='50%'>
            {t('travel_history.status')}
          </Text>
          <Text fontSize='13px' fontWeight='600' textTransform='uppercase' color={invoice.status === 'issued' ? '#6172F3' : invoice.status === 'cancelled' ? '#98A2B3' : '#C6901E'}>
            {t(`travel_history.${invoice.status}`)}
          </Text>
        </Flex>
        <Flex gap='4px'>
          <Text fontSize='13px' fontWeight='400' w='50%'>
            {t('travel_history.credit_note')}
          </Text>
          <Flex align='flex-start' gap='4px'>
            {invoice.creditNoteURL ? (
              <Flex w='100%' justify='space-between' align='center' mr='16px'>
                <Text fontSize='13px' fontWeight='600'>
                  {invoice.creditNoteName}
                </Text>
                <Text fontSize='13px'>{invoice.name}</Text>
                <a href={invoice.creditNoteURL} target='_blank' rel='noreferrer' download>
                  <Image src={downloadIcon} />
                </a>
              </Flex>
            ) : (
              <Text fontSize='13px' fontWeight='600'>
                —
              </Text>
            )}
          </Flex>
        </Flex>
        <Divider border='1px solid #D0D5DD' marginBottom='8px' marginTop='8px' />
      </Flex>
    </>
  )
}
