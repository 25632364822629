import { useTranslation } from 'react-i18next'
import { Box, Center, Flex, Grid, Image, Spinner, Text, useDisclosure, useToast } from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'
import { Button as UbiButton } from '@ubirider/pick-component-library'
import { DeleteCardModal } from '../../../../modals/DeleteCardModal'
import { deleteCard } from '../../../../api/cards'
import { SwitchIOModal } from '../../../../modals/SwitchIOModal/SwitchIOModal'
import config from '../../../../config/configLoader'

import trainCard from '../../../../assets/train_card.png'
import contactless from '../../../../assets/contactless.svg'
import checkWhiteIcon from '../../../../assets/check_circle_white.svg'
import closeWhiteIcon from '../../../../assets/cross_white_24.svg'
import whitePlus from '../../../../assets/plus_white.svg'

import { Card } from './components/Card'

export const CardsMenu = ({ setAdded, cards, loading }) => {
  const { t } = useTranslation()
  const toast = useToast()
  const toastIdRef = useRef()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: isOpenSwitchIO, onOpen: onOpenSwitchIO, onClose: onCloseSwitchIO } = useDisclosure()

  const [showAllCards, setShowAllCards] = useState(false)
  const [removeLoading, setRemoveLoading] = useState(false)
  const [removeCardToken, setRemoveCardToken] = useState(null)
  const [switchTrigger, setSwitchTrigger] = useState(false)

  function close() {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current)
    }
  }

  const onConfirm = async () => {
    setRemoveLoading(true)
    try {
      await deleteCard(removeCardToken)
      cards = cards.filter(card => card.token !== removeCardToken)
      toastIdRef.current = toast({
        position: 'top',
        duration: 5000,
        isClosable: true,
        render: () => (
          <Box alignItems='center' color='white' bg='#01AE70' border='1px solid #067F5B' boxShadow='box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.05)' borderRadius='4px'>
            <Flex justifyContent='space-between' gap='16px' padding='16px'>
              <Image src={checkWhiteIcon} />
              <Text fontSize='14px' fontWeight='600'>
                {t('home.payment_card_deleted')}
              </Text>
              <Image cursor='pointer' onClick={close} src={closeWhiteIcon} />
            </Flex>
          </Box>
        ),
      })
    } catch (error) {
      toast({
        description: 'Error.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
    onClose()
    setRemoveLoading(false)
    setRemoveCardToken(null)
  }

  useEffect(() => {
    if (switchTrigger) onOpenSwitchIO()
  }, [switchTrigger])

  return (
    <Flex bg={{ sm: '', md: 'neutral.100', lg: 'neutral.100', xl: 'neutral.100' }} rounded='8px' padding={{ sm: '0', md: '24px', lg: '24px', xl: '24px' }}>
      <Flex gap={{ sm: '24px', md: '16px', lg: '16px', xl: '16px' }} direction='column' width='100%'>
        <Flex gap={{ sm: '8px', md: '16px', lg: '16px', xl: '16px' }}>
          <Flex w='36px'>
            <Image src={contactless} />
          </Flex>
          <Text fontWeight='700' fontSize='24px' display={{ sm: 'none', lg: 'flex' }}>
            {t('home.my_contactless_bank_cards')}
          </Text>
          <Text fontWeight='700' fontSize='24px' display={{ sm: 'flex', lg: 'none' }}>
            {t('home.my_portal')}
          </Text>
        </Flex>
        {loading ? (
          <Center minH='240px'>
            <Spinner thickness='6px' color='neutral.500' size='xl' />
          </Center>
        ) : cards != null && cards.length > 0 ? (
          <>
            <Box>
              <UbiButton
                onClick={() => {
                  setSwitchTrigger(true)
                }}
                size='small'
                style='ghost'
                type='primary'
                leftIconProps={{ src: whitePlus }}
                label={t('home.add_payment_method')}
              />
            </Box>

            <Flex direction='column' overflow='hidden' align='center' mb='-24px' display={{ sm: 'none', md: 'flex' }}>
              <Grid
                templateColumns={{
                  sm: 'repeat(auto-fill, minmax(0, 304px))', // Cards max out at 304px
                  md: 'repeat(auto-fill, minmax(0, 304px))',
                  lg: 'repeat(auto-fill, minmax(0, 304px))',
                  xl: 'repeat(auto-fill, minmax(0, 376px))',
                  xxl: 'repeat(auto-fill, minmax(0, 376px))',
                }}
                gap='24px'
                overflowX='scroll'
                w='100%'
                pb='24px'
                justifyContent={{ lg: 'space-between' }}
                sx={{ '::-webkit-scrollbar': { display: 'none' } }}
              >
                {!showAllCards &&
                  cards.slice(0, 2).map((card, i) => (
                    <Card
                      key={i}
                      card={card}
                      onRemove={() => {
                        setRemoveCardToken(card.token)
                        onOpen()
                      }}
                    />
                  ))}

                {showAllCards &&
                  cards.map((card, i) => (
                    <Card
                      key={i}
                      card={card}
                      onRemove={() => {
                        setRemoveCardToken(card.token)
                        onOpen()
                      }}
                    />
                  ))}
              </Grid>
            </Flex>
            <Flex direction='column' overflow='hidden' align='center' mr='-24px' ml='-24px' display={{ sm: 'flex', md: 'none' }}>
              <Grid
                templateColumns={{
                  sm: 'repeat(' + cards.length + ', 1fr)',
                  md: 'repeat(2, 1fr)',
                  lg: 'repeat(2, 1fr)',
                  xl: 'repeat(2, 1fr)',
                  xxl: 'repeat(3, 1fr)',
                }}
                gap='24px'
                overflowX='scroll'
                w='100%'
                pb='24px'
                pl='24px'
                sx={{ '::-webkit-scrollbar': { display: 'none' } }}
              >
                {cards.map((card, i) => (
                  <Card
                    key={i}
                    card={card}
                    onRemove={() => {
                      setRemoveCardToken(card.token)
                      onOpen()
                    }}
                  />
                ))}
              </Grid>
            </Flex>

            {cards.length > 2 && (
              <Flex justify='flex-end' display={{ sm: 'none', md: 'flex' }}>
                <UbiButton
                  onClick={() => {
                    setShowAllCards(!showAllCards)
                  }}
                  style='ghost'
                  type='primary'
                  label={showAllCards ? t('home.see_less_methods') : t('home.see_all_methods')}
                />
              </Flex>
            )}
          </>
        ) : (
          <Flex
            direction='column'
            my={{ sm: '', md: '48px', lg: '48px', xl: '48px' }}
            mx={{ sm: '', md: '96px', lg: '96px', xl: '96px' }}
            justifyContent='center'
            alignItems='center'
            textAlign='center'
            gap='24px'
          >
            <Image src={trainCard} w='260px' />
            <Center display={{ sm: 'flex', md: 'flex', lg: 'none', xl: 'none' }}>
              <Box>
                <UbiButton
                  onClick={() => {
                    setSwitchTrigger(true)
                  }}
                  style='solid'
                  type='primary'
                  leftIconProps={{ src: whitePlus }}
                  label={t('home.add_payment_method')}
                />
              </Box>
            </Center>
            <Text fontSize={{ sm: '18px', md: '18px', lg: '14px', xl: '14px' }} color='neutral.800' textAlign='center'>
              {t('home.my_contactless_bank_cards_empty_state', { operatorName: config.name })}
            </Text>
            <Center display={{ sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }}>
              <Box>
                <UbiButton
                  onClick={() => {
                    setSwitchTrigger(true)
                  }}
                  style='solid'
                  type='primary'
                  leftIconProps={{ src: whitePlus }}
                  label={t('home.add_payment_method')}
                />
              </Box>
            </Center>
          </Flex>
        )}
      </Flex>
      <DeleteCardModal isOpen={isOpen} onClose={onClose} onConfirm={onConfirm} loading={removeLoading} />
      <SwitchIOModal onOpen={onOpenSwitchIO} onClose={onCloseSwitchIO} isOpen={isOpenSwitchIO} isAdded={setAdded} switch={switchTrigger} setSwitch={setSwitchTrigger} />
    </Flex>
  )
}
