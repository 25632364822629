import { Flex, Image, Text, Tooltip, useDisclosure } from '@chakra-ui/react'
import { Text as UbiText, Column } from '@ubirider/pick-component-library'
import { useTranslation } from 'react-i18next'

import { TooltipModal } from '../../../../../../../../modals/TooltipModal/TooltipModal'
import informationIcon from '../../../../../../../../assets/information_purple.svg'

export const BlueSection = props => {
  const { t } = useTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Column
        css={{
          backgroundColor: '$primary100',
          borderRadius: '4px',
          p: '8px',
          marginBlock: '16px',
          gap: '8px',
          flex: '1',
        }}
      >
        <Flex gap='8px' justify={{ sm: 'space-between', md: 'flex-start', lg: 'flex-start', xl: 'flex-start' }}>
          <UbiText type='textXS' weight='bold' css={{ color: '$primary500' }}>
            {t('travel_history.actual_made_trip')}
          </UbiText>
          <Tooltip
            padding='12px 23px 13px 23px'
            borderRadius='4px'
            bg='secondary.500'
            color='secondary.25'
            hasArrow
            label={t('travel_history.actual_made_trip_tooltip')}
            placement='right'
          >
            <Image src={informationIcon} display={{ sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }} boxSize='20px' />
          </Tooltip>
          <Flex as='button' onClick={onOpen} display={{ sm: 'flex', md: 'flex', lg: 'none', xl: 'none' }}>
            <Image src={informationIcon} boxSize='20px' />
          </Flex>
        </Flex>
        <Flex
          gap={{ sm: '8px', md: '48px', lg: '48px', xl: '48px' }}
          justifyContent={{ sm: 'space-evenly', md: 'start', lg: 'start', xl: 'start' }}
          direction={{ sm: 'column', md: 'row', lg: 'row', xl: 'row' }}
        >
          <Flex gap={{ sm: '4px', md: '8px', lg: '8px', xl: '8px' }} direction={{ sm: 'column', md: 'row', lg: 'row', xl: 'row' }}>
            <UbiText type='textXS' css={{ color: '$primary500', fontSize: '13px' }}>
              {t('travel_history.origin_destination')}
            </UbiText>
            <UbiText type='textXS' weight='bold' css={{ color: '$primary500', fontSize: '13px' }}>
              {props.origin} - {props.destination}
            </UbiText>
          </Flex>
          <Flex gap={{ sm: '4px', md: '8px', lg: '8px', xl: '8px' }} direction={{ sm: 'column', md: 'row', lg: 'row', xl: 'row' }}>
            <UbiText type='textXS' css={{ color: '$primary500', fontSize: '13px' }}>
              {t('travel_history.charged_amount')}
            </UbiText>
            <UbiText type='textXS' weight='bold' css={{ color: '$primary500', fontSize: '13px' }}>
              {(Math.round(props.amount * 100) / 100).toFixed(2)}€
            </UbiText>
          </Flex>
        </Flex>
      </Column>
      <TooltipModal onOpen={onOpen} onClose={onClose} isOpen={isOpen} text={t('travel_history.actual_made_trip_tooltip')} />
    </>
  )
}
