import React from 'react'
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'
import { Flex } from '@chakra-ui/react'
import i18next from 'i18next'
import { setLanguage } from '@ubirider/pick-component-library'
import config from './config/configLoader'
import { AuthProvider } from './AuthProvider'
import Landing from './pages/Auth/Landing/Landing'
import { Header } from './pages/Home/components/Header'
import { Home } from './pages/Home'
import { Register } from './pages/Auth/Register/Register'
import { Login } from './pages/Auth/Login/Login'
import { Layout } from './Layout'
import { PrivateRoute } from './PrivateRoute'
import { Reset } from './pages/Auth/Reset/Reset'
import { NewPassword } from './pages/Auth/NewPassword/NewPassword'
import { Account } from './pages/Account'
import TravelHistory from './pages/TravelHistory/TravelHistory'
import { Faqs } from './pages/Faqs'
import { Billing } from './pages/Billing'
import { Notifications } from './pages/Notifications'
import { CustomerSupport } from './pages/CustomerSupport'
import { UserDeleteInfo } from './pages/Auth/UserDeleteInfo/UserDeleteInfo'
import { UserProvider, useUser } from './context/UserContext'

export const getLanguageFromBrowser = language => {
  if (config.languages.includes(language)) return navigator.language
  return 'EN'
}

function App() {
  const language = localStorage.getItem('Language')
  const userLang = getLanguageFromBrowser(navigator.language)

  if (language) {
    i18next.changeLanguage(language)
    setLanguage(language.toLowerCase())
  } else {
    localStorage.setItem('Language', userLang)
    i18next.changeLanguage(userLang)
    setLanguage(userLang.toLowerCase())
  }

  return (
    <AuthProvider>
      <UserProvider>
        <Router>
          <>
            <Header />
            <Flex gap='20px' direction={{ md: 'row', sm: 'column' }}>
              <Routes>
                <Route
                  element={
                    <PrivateRoute>
                      <Layout />
                    </PrivateRoute>
                  }
                >
                  <Route path='/home' exact element={<Home />} />
                  <Route path='/home/success' exact element={<Home />} />
                  <Route path='/home/fail' exact element={<Home />} />
                  <Route path='/travel-history' exact element={<TravelHistory />} />
                  <Route path='/faqs' exact element={<Faqs />} />
                  <Route path='/account' exact element={<Account />} />
                  <Route path='/billing-information' exact element={<Billing />} />
                  <Route path='/notifications' exact element={<Notifications />} />
                  <Route path='/support' exact element={<CustomerSupport />} />
                </Route>

                <Route element={<Landing />}>
                  <Route path='/' element={<Login />} />
                  <Route path='/register' element={<Register />} />
                  <Route path='/reset' element={<Reset />} />
                  <Route path='/change-password' element={<NewPassword />} />
                </Route>

                <Route path='/delete-information' element={<UserDeleteInfo />} />

                <Route path='*' element={<Navigate to='/home' />} />
              </Routes>
            </Flex>
          </>
        </Router>
      </UserProvider>
    </AuthProvider>
  )
}

export default App
