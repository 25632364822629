import { useContext } from 'react'
import { Navigate } from 'react-router-dom'

import { AuthContext } from './AuthProvider'

export { PrivateRoute }

function PrivateRoute({ children }) {
  const auth = useContext(AuthContext)

  if (!auth.user) {
    return <Navigate to='/' />
  }

  return children
}
